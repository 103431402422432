// not paginated table (used in ListPage what already paginates this component)
// react modules
import React from "react";

// third-party modules
import {
  badgeCell,
  BadgeTheme,
  Button,
  CellDataType,
  noWrapCell,
  Switch,
  Table
} from "@onehq/anton";
import {
  ADD,
  GrowlAlertDispatch,
  Link,
  useDispatchGrowlContext
} from "@onehq/framework";

// app modules
import {
  CreateOptOutMutation,
  CustomCurrentUserQuery,
  DestroyOptOutMutation,
  MessagingServiceType,
  OptOutType,
  TextListFieldsFragment,
  TextType,
  useCreateOptOutMutation,
  useDestroyOptOutMutation
} from "../../generated/graphql";
import {
  addSpacesBetweenWords,
  formatDate,
  formatPhone,
  textStatusBadgeColor
} from "../../utils";
import { BaseTableProps } from "../../types";
import { PHONES_PATH, PROJECTS_PATH, TEXTS_PATH } from "../../constants";

export enum TypesOfTextList {
  error = "error", // add error column to default columns
  default = "default" // dont change default columns
}

export interface TextTableProps extends BaseTableProps<TextListFieldsFragment> {
  currentUser?: CustomCurrentUserQuery["currentUser"];
  type?: TypesOfTextList;
}

export interface TextTableColumnsProps {
  showError?: boolean;
  testTextTable?: boolean;
}

export const getTextTableColumns = ({
  showError,
  testTextTable
}: TextTableColumnsProps = {}) => {
  const columns: any[] = [
    {
      header: "Text",
      accessorKey: "openButton",
      disableSortBy: true
    },
    {
      header: "Texter",
      accessorKey: "texter",
      disableSortBy: true
    },
    {
      header: "Body",
      accessorKey: "renderedMessage",
      cell: noWrapCell,
      columnMaxWidth: 310
    },
    {
      header: "Service Type",
      accessorKey: "messagingServiceType",
      disableSortBy: true
    },
    {
      header: "Text Status",
      accessorKey: "textStatus",
      disableSortBy: true
    },
    {
      header: "From Phone Number",
      accessorKey: "fromPhone",
      disableSortBy: true
    },
    {
      header: "To Phone Number",
      accessorKey: "toPhone",
      disableSortBy: true
    },
    {
      header: "Sent At",
      accessorKey: "sentAt"
    },
    {
      header: "Tracking ID",
      accessorKey: "trackingId"
    },
    {
      header: "Project Number",
      accessorKey: "projectNumber",
      disableSortBy: true
    },
    {
      header: "Project State",
      accessorKey: "projectState",
      disableSortBy: true
    },
    {
      header: "Project Name",
      accessorKey: "projectName",
      disableSortBy: true
    }
  ];

  // add error message if showError is true
  if (showError)
    columns.push({
      header: "Error Message",
      accessorKey: "errorMessage",
      dataType: "longText" as CellDataType
    });
  if (testTextTable)
    columns.push({
      header: "Action",
      accessorKey: "resendButton",
      disableSortBy: true
    });
  // add opt out button as last column
  columns.push({
    header: "Opt Out",
    accessorKey: "optOutButton"
  });

  return columns;
};

export const useTextTableDataFormatter = (
  data?: Array<TextListFieldsFragment>,
  openResend?: (
    toPhone: TextListFieldsFragment["toPhoneNumber"],
    fromPhone: TextListFieldsFragment["fromPhoneNumber"],
    message: string
  ) => void
) => {
  const alert: GrowlAlertDispatch = useDispatchGrowlContext();

  // action for creating optOut
  const [createOptOut] = useCreateOptOutMutation({
    onCompleted: (response: CreateOptOutMutation) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (Object.keys(response?.createOptOut?.errors as Object).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: `Opt out has been created successfully`,
            variant: "success"
          }
        });
      } else {
        alert({
          type: ADD,
          payload: {
            title: "Error creating opt out",
            variant: "error"
          }
        });
      }
    },
    onError: err => {
      console.error(err); // the error if that is the case
    }
  });

  // action for deleting optOut
  const [destroyOptOut] = useDestroyOptOutMutation({
    onCompleted: (response: DestroyOptOutMutation) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (Object.keys(response?.destroyOptOut?.errors as Object).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: `Opt out has been deleted successfully`,
            variant: "success"
          }
        });
      } else {
        alert({
          type: ADD,
          payload: {
            title: "Error removing opt out",
            variant: "error"
          }
        });
      }
    },
    onError: err => {
      console.error(err); // the error if that is the case
    }
  });

  // this button will render in the table
  const optOutButton = (item: TextListFieldsFragment) => {
    const theresOptOut = item.optOut?.id;
    const onClick = () => {
      if (theresOptOut) {
        destroyOptOut({
          variables: {
            id: theresOptOut
          }
        }).catch(() => {
          console.log("error");
        });
      } else {
        createOptOut({
          variables: {
            attributes: {
              textId: item.id,
              clientId: item.project.client.id,
              phoneId:
                item.textType === TextType.Reply
                  ? item.fromPhoneNumber?.id
                  : item.toPhoneNumber?.id,
              optOutType: OptOutType.Manual
            }
          }
        }).catch(() => {
          console.log("error");
        });
      }
    };
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Switch checked={!!theresOptOut} onChange={onClick} />
      </div>
    );
  };

  const resendButton = (
    toPhone: TextListFieldsFragment["toPhoneNumber"],
    fromPhone: TextListFieldsFragment["fromPhoneNumber"],
    message: string
  ) => {
    return (
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => openResend && openResend(toPhone, fromPhone, message)}
          variant="icon"
        >
          Resend
        </Button>
      </div>
    );
  };

  return [
    ...(data?.map((item: TextListFieldsFragment) => {
      const {
        id,
        serviceType,
        project,
        renderedMessage,
        texter,
        textStatus,
        trackingId,
        fromPhoneNumber,
        toPhoneNumber,
        sentAt,
        optOut
      } = item;

      return {
        projectName: (
          <Link to={`/${PROJECTS_PATH}/${project?.id}/overview`}>
            {project?.name}
          </Link>
        ),
        projectNumber: project.number,
        projectState: project.state?.name,
        texter: <Link to={`/users/${texter?.id}`}>{texter?.name}</Link>,
        renderedMessage: renderedMessage || "--",
        messagingServiceType: badgeCell({
          value: serviceType || "",
          color:
            serviceType === MessagingServiceType.Mms
              ? "blue"
              : ("green" as BadgeTheme)
        }),
        textStatus: badgeCell({
          value: addSpacesBetweenWords(textStatus),
          color: textStatusBadgeColor(textStatus) as BadgeTheme
        }),
        fromPhone: (
          <Link to={`/${PHONES_PATH}/${fromPhoneNumber?.id}`}>
            {formatPhone(fromPhoneNumber?.number || "")}
          </Link>
        ),
        toPhone: (
          <Link to={`/${PHONES_PATH}/${toPhoneNumber?.id}`}>
            {formatPhone(toPhoneNumber?.number || "")}
          </Link>
        ),
        trackingId,
        sentAt: formatDate(sentAt),
        optOut,
        optOutButton: optOutButton(item),
        resendButton: resendButton(
          item.toPhoneNumber,
          item.fromPhoneNumber,
          item.body || ""
        ),
        openButton: <Link to={`/${TEXTS_PATH}/${id}/overview`}>open</Link>,
        errorMessage: item.errorMessage
      };
    }) || [])
  ];
};

const TextTable = (props: TextTableProps) => {
  const data = props.data || [];
  const loading = props.loading;
  const type = props.type || TypesOfTextList.default;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={getTextTableColumns({
        showError: type === TypesOfTextList.error
      })}
      data={useTextTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default TextTable;
