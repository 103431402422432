import {
  ListSummaryOption,
  GetListSummaryDocument,
  TextQueryFilterFields,
  FilterOperation,
  TextType,
  GetTextsListCsvDocument,
  TeamQueryFilterFields,
  GetTeamsListCsvDocument,
  ProjectTexterQueryFilterFields,
  GetProjectTextersListCsvDocument,
  TextInsightsDocument,
  GetOptOutTextsListDocument,
  TextStatus
} from "../generated/graphql";
import { BaseFilterTabsProps } from "../types";
import { PAGE_SIZE, addSpacesBetweenWords } from "../utils";

export const PROJECTS_PATH = "projects";

export const PROJECT_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  TEAMS: "Teams",
  TEXTERS: "Texters",
  TEXTS: "Texts",
  TEST_TEXTS: "Test Texts",
  SEED_TEXTS: "Seed Texts",
  TEXTS_INSIGHTS: "Insights",
  DUPLICATE_PHONES: "Duplicate Phones",
  INVALID_PHONES: "Invalid Phones",
  OPT_OUT_PHONES: "Opt Out Phones"
};

export const PROJECT_VIEW_LINKS = [
  {
    id: PROJECT_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    text: PROJECT_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    name: PROJECT_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    path: "/overview"
  },
  {
    id: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEAMS,
    text: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEAMS,
    name: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEAMS,
    path: "/teams"
  },
  {
    id: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTERS,
    text: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTERS,
    name: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTERS,
    path: "/texters"
  },
  {
    id: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    text: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    name: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    path: "/test-texts"
  }
];

type ProjectFilterTabsProps = BaseFilterTabsProps & {
  teamsInProject?: string[];
  selectedTextType?: string;
};

export const ReceivedTypes = ["ReceivedOptedOut", "ReceivedNotOptedOut"];

export const projectTabFilters = ({
  id,
  teamsInProject = [],
  selectedTextType = "",
  selectedTab
}: ProjectFilterTabsProps) => {
  const isReceived = ReceivedTypes.includes(selectedTextType);
  const textStatus = isReceived ? TextStatus.Received : selectedTextType;
  const filterOptions = {
    [PROJECT_MENU_ANCHOR_VIEW_LINKS.DUPLICATE_PHONES]: {
      variables: {
        projectId: id,
        option: ListSummaryOption.Duplicates,
        first: PAGE_SIZE
      },
      listDocument: GetListSummaryDocument
    },
    [PROJECT_MENU_ANCHOR_VIEW_LINKS.INVALID_PHONES]: {
      variables: {
        projectId: id,
        option: ListSummaryOption.Invalids,
        first: PAGE_SIZE
      },
      listDocument: GetListSummaryDocument
    },
    [PROJECT_MENU_ANCHOR_VIEW_LINKS.OPT_OUT_PHONES]: {
      variables: {
        projectId: id,
        option: ListSummaryOption.Invalids,
        first: PAGE_SIZE
      },
      listDocument: GetListSummaryDocument
    },
    [PROJECT_MENU_ANCHOR_VIEW_LINKS.SEED_TEXTS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: TextQueryFilterFields.ProjectId,
            operation: FilterOperation.Equal,
            value: id
          },
          {
            field: TextQueryFilterFields.TextTypeId,
            operation: FilterOperation.Equal,
            value: `TextType::::${TextType.Seed}`
          }
        ]
      },
      listDocument: GetTextsListCsvDocument
    },
    [PROJECT_MENU_ANCHOR_VIEW_LINKS.TEAMS]: {
      variables: {
        first: PAGE_SIZE,
        filters: teamsInProject.length
          ? [
              {
                field: TeamQueryFilterFields.Id,
                operation: FilterOperation.In,
                arrayValues: teamsInProject
              }
            ]
          : []
      },
      listDocument: GetTeamsListCsvDocument
    },
    [PROJECT_MENU_ANCHOR_VIEW_LINKS.TEST_TEXTS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: TextQueryFilterFields.ProjectId,
            operation: FilterOperation.Equal,
            value: id
          },
          {
            field: TextQueryFilterFields.TextTypeId,
            operation: FilterOperation.Equal,
            value: `TextType::::${TextType.Test}`
          }
        ]
      },
      listDocument: GetTextsListCsvDocument
    },
    [PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTERS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: ProjectTexterQueryFilterFields.ProjectId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetProjectTextersListCsvDocument
    },
    [PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTS]: {
      variables: {
        first: PAGE_SIZE,
        projectId: id,
        withoutOptOut:
          selectedTextType === "ReceivedNotOptedOut" ? true : false,
        filters: [
          {
            field: TextQueryFilterFields.ProjectId,
            operation: FilterOperation.Equal,
            value: id
          },
          isReceived
            ? {
                field: TextQueryFilterFields.TextTypeId,
                operation: FilterOperation.Equal,
                value: `TextType::::${TextType.Reply}`
              }
            : {
                field: TextQueryFilterFields.TextTypeId,
                operation: FilterOperation.In,
                arrayValues: [
                  `TextType::::${TextType.List}`,
                  `TextType::::${TextType.Manual}`
                ]
              },
          {
            field: TextQueryFilterFields.TextStatusId,
            operation: FilterOperation.Equal,
            value: `TextStatus::::${addSpacesBetweenWords(textStatus)}`
          }
        ]
      },
      listDocument:
        ["OptOut", ...ReceivedTypes].indexOf(selectedTextType) !== -1
          ? GetOptOutTextsListDocument
          : GetTextsListCsvDocument
    },
    [PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTS_INSIGHTS]: {
      variables: { projectId: id, first: PAGE_SIZE },
      listDocument: TextInsightsDocument
    }
  };

  return filterOptions[selectedTab];
};
